export function qsa(qs, cb) {

	// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
	const _executeQsa = (qs, cb) => {

		// were we passed a selector string or a node list?
		const nodeList = (typeof qs === 'string') ? document.querySelectorAll(qs) : qs;

		if (nodeList instanceof NodeList) {
			nodeList.forEach(function (el, i, list) {
				cb(el, i, list);
			});
		} else if (nodeList) {
			cb(nodeList, 0, nodeList);
		}

		return nodeList;
	};

	// If we were passed an array, then go through
	// each item and execute the callback, and add it to
	// an array to return.
	if (Array.isArray(qs)) {
		const chunks = [];
		qs.forEach((item) => {
			chunks.push(_executeQsa(item, cb));
		});

		return chunks;

	}
	// If we were passed a node list or a string...
	return _executeQsa(qs, cb);

}