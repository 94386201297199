export function header(opts = null) {
  return {
    shelfNode: '',
    menuOpen: false,
    menuOpenTimeout: null,
    menuOpening: true,
    menuOpeningTimeout: null,

    init() {
      this.$refs.menuToggle.addEventListener('click', () => {
        this.mobileMenu = !this.mobileMenu;
        this.mobileNode = '';
        this.$dispatch('menu-open', { open: this.mobileMenu });
      });
    },

    reset() {
      clearTimeout(this.menuOpenTimeout);
      clearTimeout(this.menuOpeningTimeout);
      if(!this.menuOpen) {
        this.menuOpening = true;
        this.menuOpenTimeout = setTimeout(() => {
          this.openShelf();
        }, 300);
        return;
      }
      this.openShelf();
    },

    openShelf() {
      this.$dispatch('shelf-open', { open: true });
      this.menuOpen = true;
      this.menuOpeningTimeout = setTimeout(() => {
        this.menuOpening = false;
      }, 150);
    },

    onLeaveMenu() {
      this.shelfNode = '';
      this.$dispatch('shelf-open', { open: false });
      clearTimeout(this.menuOpenTimeout);
      this.menuOpening = true;
      this.menuOpen = false;
    },

    onEnterItem(slug = null) {
      if(!slug) return;
      this.shelfNode = slug;
      this.reset();
    },

    onFocusItem(slug = null) {
      if(!slug) return;
      this.shelfNode = slug;
      this.menuOpen = true;
      this.menuOpening = false;
      this.$dispatch('shelf-open', { open: true })
    }
  };
}
