import { Exposure } from "@js/libs/exposure";

export function moodboard(opts = {}) {
    let moodboard = null;
    let moodboardEl = null;

    return {
        init() {
            moodboardEl = this.$refs.moodboardEl || this.$root;
            moodboard = new Exposure(moodboardEl, { maxHeight: 460 });
        }
    };
}