export function app() {
    return {
        overflowHidden: false,
        shelfNode: '', 
        mobileMenu: false, 
        mobileNode: '',
        lightbox: false, 

        init() {}
    };
}
  