import mapboxgl from 'mapbox-gl';

/* Helper Methods */
const isMobile = () => {
  return matchMedia('(max-width: 639px)').matches;
};

/* method to turn marketData into valid data source */
const getSourceData = function(mData) {
  return {
    type: 'FeatureCollection',
    features: mData.map(m => {
      return {
        type: 'Feature',
        properties: {
          'id': m.id,
          'section' : m.section,
          'title': m.title,
          'text': m.text,
          'linkTarget': m.linkTarget,
          'linkText': m.linkText,
          'linkUrl': m.linkUrl,
          'markerBgImgUrl': m.markerBgImgUrl,
          'sidebarBgImgUrl': m.sidebarBgImgUrl,
          'cats': m.cats
        },
        geometry: {
          type: 'Point',
          coordinates: m.lngLat
        }
      };
    })
  };
};


export function mapbox(token, opts = {}, markerData = {}) {
  mapboxgl.accessToken = token;

  /* Object for caching and keeping track of HTML marker objects */
  const allMarkers = {};

  const options = {
    cooperativeGestures: true,
    dragRotate: false,
    scrollZoom: false,
    attributionControl: false,
    projection: 'mercator',
    ... opts
  };

  return {
    isFiltered : null,
    map : null,
    zoom : null,
    resetZoom : false,
    overlayIsOpen : true,
    sidebarIsOpen: false,
    sidebarProps: {},
    resetMap() {
      this.sidebarIsOpen = false;
      this.overlayIsOpen = false;
      this.setBounds(markerData, ! this.isFiltered);
    },
    filterMarkers(id) {
      if(!id) {
        this.isFiltered = false;
        this.sidebarIsOpen = false;
        this.map.getSource('markers').setData(getSourceData(markerData));
        this.setBounds(markerData, true);
        return;
      }

      let filtered = markerData.filter(m => m.cats.split('|').includes('' + id));
      if(!filtered.length) return;

      this.isFiltered = true;
      this.sidebarIsOpen = false;
      this.map.getSource('markers').setData(getSourceData(filtered));
      this.setBounds(filtered);
    },
    showMarkerSidebar(props) {
      this.sidebarProps = props;
      this.sidebarIsOpen = true;
      this.overlayIsOpen = false;
    },
    setBounds(mData, reset = false) {
      this.zoom = null;
      this.resetZoom = false;

      if(reset && options.center && options.zoom) {
        this.map.flyTo({
          center: opts.center,
          zoom: opts.zoom
        });
        return;
      }

      if(mData.length == 1 ) {
        this.panToMarker(mData[0].lngLat, {
          // maxZoom : 4
        });
        return;
      }

      let bounds = new mapboxgl.LngLatBounds();
      mData.forEach(m => {
        let coords = m.lngLat;
        bounds.extend([coords[0], coords[1]]);
      });

      try {
        this.map.fitBounds(bounds, {
          padding: isMobile() ? 50 : 200
        });
      } catch(e) {
        console.log('unable to fit into screen; trying again without restriction');
        this.map.fitBounds(bounds);
      }
    },
    panToMarker(coords, opts) {
      var offsetX = ( isMobile() ) ? 0 : - this.$refs.sidebar.offsetWidth / 2;

      this.map.panTo( coords, {
        offset: [ offsetX, 0 ],
        ...opts
      } );
    },
    createMarker(props, coords) {
      let html = `<div>` + props.id + `</div`;
      const el = document.createElement('div');
      el.innerHTML = '<div class="marker" data-cats="' + props.cats + '" style="background-image:url('+props.markerBgImgUrl+')"></div>';

      el.addEventListener('click', () => {
        this.panToMarker(coords);
        this.showMarkerSidebar(props);
      }, false);

      return el;
    },
    init() {
      const container = this.$refs.map || null;

      if(!container) {
        console.log('map container not found');
      }

      options['container'] = container;
      this.map = new mapboxgl.Map(options);

      this.map.on('idle', () => {
        let zoom = this.map.getZoom();

        if(this.zoom === null) {
          this.zoom = zoom;
        }

        this.resetZoom = (this.zoom !== zoom);
      });

      this.map.on('load', () => {
        this.map.addSource('markers', {
          'type': 'geojson',
          data: getSourceData(markerData),
          'cluster': true,
          // 'clusterMaxZoom': 14,
          'clusterRadius': 17
        });

        /** Part 1: Clusters
         * Clustering is added via Layers.
         * Since Layers can only be discrete types,
         * we have to add 2 layers, one for the count and one for the circle behind it
         */
        const clustersLayer = {
          id: 'clusters',
          type: 'circle',
          source: 'markers',
          filter: ['has', 'point_count'],
          paint: {
            'circle-radius': 18,
            'circle-color': '#495372',
            'circle-stroke-color': '#141b30',
            'circle-stroke-width': 1,
          }
        };

        const clusterCountLayer = {
          id: 'cluster-count',
          type: 'symbol',
          source: 'markers',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ["Suisse Int'l Book"],
            'text-size': 14,
          },
          paint: {
            'text-color': '#c1cae5',
          }
        };

        // Now add the 2 cluster layers to the map
        this.map.addLayer(clustersLayer);
        this.map.addLayer(clusterCountLayer);

        // zoom to a cluster on click
        this.map.on('click', 'clusters', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
          });
          const clusterId = features[0].properties.cluster_id;
          this.map.getSource('markers').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;

              this.sidebarIsOpen = false;

              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            }
          );
        });

        /** Part 2: Markers
         * Markers are added using custom HTML
         */

        let markersOnScreen = {}; // `let` because we overwrite it below

        // function to update all visible markers on the map,
        // called below every time the map renders
        const updateMarkers = () => {

          // local cache of any markers that are visible on this render
          const visibleMarkers = {};

          // this will return all "features", either clusters or markers,
          // that are currently visible on the map,
          // which we then filter out to only return markers.
          const features = this.map.querySourceFeatures('markers').filter((f) => !f.properties.cluster);

          // for every marker on the screen, create an HTML marker for it (if we didn't yet already),
          // and add it to the map if it's not there already
          for (const feature of features) {
            const coords = feature.geometry.coordinates;
            const props = feature.properties;
            const id = props.id;

            // create marker or retrieve from local cache
            let marker = allMarkers[id];
            if(!marker) {
              const el = this.createMarker(props, coords);
              marker = allMarkers[id] = new mapboxgl.Marker({
                element: el
              }).setLngLat(coords);
            }

            // add to our local cache of markers that should be visible on this render
            visibleMarkers[id] = marker;

            if(!markersOnScreen[id]) marker.addTo(this.map);
          }

          // for every marker on screen from the previous render, remove those that are no longer visible
          for (const id in markersOnScreen) {
            if (!visibleMarkers[id]) markersOnScreen[id].remove();
          }

          // overwrite our cache of markers on screen with the visible set from this render
          markersOnScreen = visibleMarkers;
        };

        // after the GeoJSON data is loaded, update markers on the screen on every frame
        this.map.on('render', () => {
          if (!this.map.isSourceLoaded('markers')) return;
          updateMarkers();
        });

        // Map controls
        this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-left');
        this.map.addControl(new mapboxgl.NavigationControl({
          showCompass: false
        }), 'bottom-left');

        // Change cursor when hovering over clusters
        this.map.on('mouseenter', 'clusters', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        // Reset cursor when not hovering over clusters
        this.map.on('mouseleave', 'clusters', () => {
          this.map.getCanvas().style.cursor = '';
        });

        if(! options.center || ! options.zoom) {
          this.setBounds(markerData);
        }
      });
    }
  };
};
