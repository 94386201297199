import Alpine from "alpinejs";
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

import { app } from "./app.js";
import { embedAssetResizer } from './embedAssetResizer.js';
import { mapbox } from './mapbox.js';
import { moodboard } from './moodboard.js';
import { header } from './header.js';

Alpine.plugin(intersect);
Alpine.plugin(collapse);

/* data components */
Alpine.data("app", app);
Alpine.data("embedAssetResizer", embedAssetResizer);
Alpine.data("mapbox", mapbox);
Alpine.data("moodboard", moodboard);
Alpine.data("header", header);

/* start your engines */
window.Alpine = Alpine;
Alpine.start();
