let assetsChanged = false;
const isLoadPageSafe = function ({ url, layer }) {
  // To prevent any overlays from closing, we only make a full page load
  // when the link is changing the root layer.
  return url && up.layer.current.isRoot() && layer !== 'new';
};

up.on('up:assets:changed', function() {
  assetsChanged = true;
});

up.on('up:link:follow', function(event) {
  if (assetsChanged && isLoadPageSafe(event.renderOptions)) {
    // Prevent the render pass
    event.preventDefault();

    // Make full page load without Unpoly
    up.network.loadPage(event.renderOptions);
  }
});

up.on('up:framework:booted', (event) => {
  document.documentElement.classList.add('up');
});

up.on('up:layer:opened', (event) => {
  if(event.layer.stack.layers.length > 1) {
    document.documentElement.style.overflow = 'hidden';
  }
});

up.on('up:layer:dismiss', (event) => {
  if(event.layer.stack.layers.length > 1) {
    document.documentElement.style.overflow = 'auto';
  }
});

up.on('up:layer:accept', (event) => {
  if(event.layer.stack.layers.length > 1) {
    document.documentElement.style.overflow = 'auto';
  }
});

/**
 * @TODO device detection to limit scope?
 * Safari bug doesn't size srcset when loaded over ajax
 * https://www.debugcn.com/en/article/16843265.html
 * Also has an issue with video!
 */
up.on('up:fragment:inserted', (event, fragment) => {
  const els = fragment.querySelectorAll('img[srcset], video');
  if( ! els) {
    return;
  }
  els.forEach(function(el) {
    el.outerHTML = el.outerHTML;
  });
});
