/**
 * Array of CSS selectors to follow & preload (see below)
 * @type {string[]}
 */
const yesSelectors = ['a[href]'];

/**
 * Array of CSS selectors to NOT follow & preload (see below)
 * @type {string[]}
 */
const noSelectors = [
  'a[href*=actions]',
  'a[href*=one]',
];

/**
 * Tweak default navigation behavior
 * https://unpoly.com/navigation
 * @type {boolean}
 */
up.fragment.config.navigateOptions.autoFocus = false;
up.fragment.config.navigateOptions.cache = false;

/**
 * Automatically follow (most) internal links
 * https://unpoly.com/up.link.config#config.followSelectors
 * https://unpoly.com/up.link.config#config.noFollowSelectors
up.link.config.followSelectors.push(...yesSelectors);
up.link.config.noFollowSelectors.push(...noSelectors);
 */

/**
 * Automatically preload (most) internal links
 * https://unpoly.com/up.link.config#config.preloadSelectors
 * https://unpoly.com/up.link.config#config.noPreloadSelectors
up.link.config.preloadSelectors.push(...yesSelectors);
up.link.config.noPreloadSelectors.push(...noSelectors);
 */

/**
 * Disable progress bar
 * @type {boolean}
 */
up.network.config.progressBar = false;

/**
 * The maximum number of responses to cache
 * https://v2.unpoly.com/up.network.config#config.cacheSize
 * @type {number}
up.network.config.cacheSize = 0;
 */
